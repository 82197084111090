// CSS imports
import styles from './Loading.module.scss';

// 3rd party imports
import React from 'react';

const Loading = (props) => {
  return (
    <div className={styles.container}>
      <div className="font_menu">Loading...</div>
    </div>
  );
};

export default Loading;
