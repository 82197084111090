// CSS imports
import styles from './Mobile.module.scss';

// 3rd party components import
import React from 'react';
import classNames from 'classnames/bind';

// Own components
import TextWithLinebreaks from '../../utils/TextWithLinebreaks';
import LineWithHyphens from '../../utils/LineWithHyphens';
import { RESOURCE_URL_PREFIX } from '../../../Constants';

// Assets
import imgBubble01 from '../../../assets/bubbles/mobile/bio/bubble_1.png';
import imgBubble02 from '../../../assets/bubbles/mobile/bio/bubble_2.png';

// Setup
const cx = classNames.bind(styles);

const Mobile = ({ data }) => {
  // Construct classnames
  const classesBubble01 = cx({
    bubble: true,
    bubble_1: true,
  });
  const classesBubble02 = cx({
    bubble: true,
    bubble_2: true,
  });
  return (
    <div>
      {/* Bubbles */}
      <div className={classesBubble01}>
        <img src={imgBubble01} alt="" />
      </div>
      <div className={classesBubble02}>
        <img src={imgBubble02} alt="" />
      </div>
      {/* German Unit */}
      <div className={styles.unit}>
        <div className={styles.portrait_img}>
          <img
            src={RESOURCE_URL_PREFIX + data.photo_portrait_german.path}
            alt=""
          />
        </div>
        <div className={styles.text_column}>
          <div className="font_text">
            <TextWithLinebreaks
              text={data.text_german_column_1}
              key_prefix="bio-text-de-c1-l"
            />
          </div>
        </div>
        <div className={styles.landscape_img}>
          <img
            src={RESOURCE_URL_PREFIX + data.photo_landscape_german.path}
            alt=""
          />
        </div>
        <div className={styles.text_column}>
          <div className="font_text">
            <TextWithLinebreaks
              text={data.text_german_column_2}
              key_prefix="bio-text-de-c2-l"
            />
          </div>
        </div>
        <div className={styles.quote_text}>
          <div className="font_menu">
            <LineWithHyphens line={data.quote_text_german} />
          </div>
        </div>
        <div className={styles.quote_author_and_magazine}>
          <div className="font_text">{data.quote_author_german}</div>
          <div className="font_text">{'— ' + data.quote_magazine_german}</div>
        </div>
      </div>
      {/* English Unit */}
      <div className={styles.unit}>
        <div className={styles.portrait_img}>
          <img
            src={RESOURCE_URL_PREFIX + data.photo_portrait_english.path}
            alt=""
          />
        </div>
        <div className={styles.text_column}>
          <div className="font_text" lang="en">
            <TextWithLinebreaks
              text={data.text_english_column_1}
              key_prefix="bio-text-en-c1-l"
            />
          </div>
        </div>
        <div className={styles.landscape_img}>
          <img
            src={RESOURCE_URL_PREFIX + data.photo_landscape_english.path}
            alt=""
          />
        </div>
        <div className={styles.text_column}>
          <div className="font_text" lang="en">
            <TextWithLinebreaks
              text={data.text_english_column_2}
              key_prefix="bio-text-en-c2-l"
            />
          </div>
        </div>
        <div className={styles.quote_text}>
          <div className="font_menu" lang="en">
            <LineWithHyphens line={data.quote_text_english} />
          </div>
        </div>
        <div className={styles.quote_author_and_magazine}>
          <div className="font_text" lang="en">
            {data.quote_author_english}
          </div>
          <div className="font_text" lang="en">
            {'— ' + data.quote_magazine_english}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mobile;
