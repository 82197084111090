// CSS imports
import styles from './Contact.module.scss';

// 3rd party imports
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import classNames from 'classnames/bind';

// Own code imports
import {
  processContactMobilePhotoQuery,
  processContactLinksQuery,
} from './Utils';
import { getContactMobilePhoto, getContactLinks } from '../../../API';
import { RESOURCE_URL_PREFIX, SEO_DESCRIPTION } from '../../../Constants';
import Loading from '../../utils/Loading';

// Assets
import imgBubble01 from '../../../assets/bubbles/desktop/contact/bubble_1.png';
import imgBubble02 from '../../../assets/bubbles/desktop/contact/bubble_2.png';
import imgBubble03 from '../../../assets/bubbles/desktop/contact/bubble_3.png';
import imgBubbleMobile01 from '../../../assets/bubbles/mobile/contact/bubble_1.png';
import imgBubbleMobile02 from '../../../assets/bubbles/mobile/contact/bubble_2.png';

// Setup
const cx = classNames.bind(styles);

// Props
// - isMobie is expected to be either true or false
const Contact = ({ isMobile }) => {
  // Fetch and process contact mobile photo
  const contactMobilePhotoQuery = useQuery(
    'contact-mobile-photo',
    getContactMobilePhoto
  );
  const contactMobilePhoto = processContactMobilePhotoQuery(
    contactMobilePhotoQuery
  );
  // Fetch and process contact links
  const contactLinksQuery = useQuery('contact-links', getContactLinks);
  const contactLinks = processContactLinksQuery(contactLinksQuery);
  // Construct link classes (switch font across different screens)
  const linkClasses = cx({
    link: true,
    font_menu: !isMobile,
    font_text: isMobile,
  });
  // Construct bubble classes
  // Construct bubble classes
  const classesBubble01 = cx({
    bubble: true,
    bubble_1: true,
  });
  const classesBubble02 = cx({
    bubble: true,
    bubble_2: true,
  });
  const classesBubble03 = cx({
    bubble: true,
    bubble_3: true,
  });
  const classesImpressumContainer = cx({
    font_text: true,
    impressum_container: true,
  });

  return (
    <div className={styles.container}>
      {/* Head Metadata */}
      <Helmet>
        <title>Annina Mossoni — Contact</title>
        <meta name="description" content={SEO_DESCRIPTION} />
      </Helmet>
      {/* Only display content if data is loaded */}
      {contactMobilePhotoQuery.isLoading || contactLinksQuery.isLoading ? (
        <Loading />
      ) : (
        <div className={styles.content_container}>
          {/* Display appropriate bubbles */}
          {isMobile ? (
            <div>
              <div className={classesBubble01}>
                <img src={imgBubbleMobile01} alt="" />
              </div>
              <div className={classesBubble02}>
                <img src={imgBubbleMobile02} alt="" />
              </div>
            </div>
          ) : (
            <div>
              <div className={classesBubble01}>
                <img src={imgBubble01} alt="" />
              </div>
              <div className={classesBubble02}>
                <img src={imgBubble02} alt="" />
              </div>
              <div className={classesBubble03}>
                <img src={imgBubble03} alt="" />
              </div>
            </div>
          )}
          <div className={styles.mobile_photo}>
            <img
              src={RESOURCE_URL_PREFIX + contactMobilePhoto.photo.path}
              alt=""
            />
          </div>
          <div className={styles.links_container}>
            {contactLinks.map((link) => {
              return (
                <div key={'link-' + link._id} className={linkClasses}>
                  <a href={link.url} target="_blank" rel="noreferrer">
                    {link.text}
                  </a>
                </div>
              );
            })}
          </div>
          <div className={classesImpressumContainer}>
            <div>&copy;2021 Annina Mossoni</div>
            <div>
              {isMobile ? (
                <div>
                  <div className={styles.vspacer} />
                  <a
                    href="mailto:buero@buerkli.org"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div>Webdesign und Entwicklung:</div>
                    <div>Patrizia Bürkli und Robin Bürkli</div>
                  </a>
                  <div className={styles.vspacer} />
                  <div>Fotos-Credits:</div>
                  <div>Larissa Odermatt</div>
                  <div>Stefan Wermuth</div>
                </div>
              ) : (
                <div>
                  <a
                    href="mailto:buero@buerkli.org"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div>
                      Webdesign und Entwicklung: Patrizia Bürkli und Robin
                      Bürkli
                    </div>
                  </a>
                  <div>Fotos-Credits: Larissa Odermatt, Stefan Wermuth</div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
