// CSS imports
import styles from './WorkCollab.module.scss';

// 3rd party imports
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';

// Own code imports
import { getProjects } from '../../../API';
import { processProjectsQuery } from './Utils';
import { SEO_DESCRIPTION } from '../../../Constants';
import Desktop from './desktop/Desktop';
import Mobile from './mobile/Mobile';
import Loading from '../../utils/Loading';

// Assets

// Setup

// Props
// - isMobile is expected to be either true or false
const WorkCollab = ({ isMobile }) => {
  const dataQuery = useQuery('projects', getProjects);
  const data = processProjectsQuery(dataQuery);

  return (
    <div className={styles.container}>
      {/* Head Metadata */}
      <Helmet>
        <title>Annina Mossoni — Work/Collab</title>
        <meta name="description" content={SEO_DESCRIPTION} />
      </Helmet>
      {/* Only display content if data is loaded */}
      {dataQuery.isLoading ? (
        <Loading />
      ) : (
        <div>{isMobile ? <Mobile data={data} /> : <Desktop data={data} />}</div>
      )}
    </div>
  );
};

export default WorkCollab;
