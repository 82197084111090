// CSS imports
import styles from './PhotoOrVideo.module.scss';

// 3rd party imports
import React, { useState } from 'react';
import classNames from 'classnames/bind';

// Own code imports
import ExternalVideo from './ExternalVideo';
import { RESOURCE_URL_PREFIX } from '../../Constants';

// Setup
const cx = classNames.bind(styles);

// Props
// - photo is expected to have value (filename)
// - video is exptected to be either null, empty or have value
const PhotoOrVideo = ({ photo, video }) => {
  // State that will be used to determine what is shown and how
  const [aspectRatioMedia, setAspectRatioMedia] = useState(1.333);
  const [showVideo, setShowVideo] = useState(false);
  // When the photo is loaded, re-set the aspectRatio
  const handleOnPhotoLoad = ({ target: img }) => {
    setAspectRatioMedia(img.width / img.height);
  };
  // When the overlay is clicked, show and play the video
  const handlePlayOverlayClicked = () => {
    setShowVideo(true);
  };
  // Construct classes
  // Construct poster classes
  const containerClasses = cx({
    container: true,
    container_pointer: video !== null && video !== '',
  });
  return (
    <div className={containerClasses}>
      {showVideo ? (
        <ExternalVideo url={video} aspectRatio={aspectRatioMedia} />
      ) : (
        <div>
          <img
            onLoad={handleOnPhotoLoad}
            src={RESOURCE_URL_PREFIX + photo}
            alt=""
          />
          {video !== null && video !== '' ? (
            <div
              onClick={handlePlayOverlayClicked}
              className={styles.play_overlay}
            >
              <svg id="triangle" viewBox="0 0 85 100">
                <polygon points="0 0, 85 50, 0 100" />
              </svg>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default PhotoOrVideo;
