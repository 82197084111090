// CSS imports
import styles from './Live.module.scss';

// 3rd party imports
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import classNames from 'classnames/bind';

// Own code imports
import { processLiveMobilePhotoQuery, useMonths, isEvenEvent } from './Utils';
import { getLiveMobilePhoto, getLiveEvents } from '../../../API';
import { RESOURCE_URL_PREFIX, SEO_DESCRIPTION } from '../../../Constants';
import Month from './subcomponents/Month';
import Loading from '../../utils/Loading';

// Assets
import imgBubble01 from '../../../assets/bubbles/desktop/live/bubble_1.png';
import imgBubble02 from '../../../assets/bubbles/desktop/live/bubble_2.png';
import imgBubbleMobile01 from '../../../assets/bubbles/mobile/live/bubble_1.png';
import imgBubbleMobile02 from '../../../assets/bubbles/mobile/live/bubble_2.png';

// Setup
const cx = classNames.bind(styles);

// Props
// - isMobie is expected to be either true or false
const Live = ({ isMobile }) => {
  // Fetch and process live mobile photo
  const liveMobilePhotoQuery = useQuery(
    'live-mobile-photo',
    getLiveMobilePhoto
  );
  const liveMobilePhoto = processLiveMobilePhotoQuery(liveMobilePhotoQuery);
  // Fetch and process live events
  const liveEventsQuery = useQuery('live-events', getLiveEvents);
  const { months, curatedEvents } = useMonths(
    liveEventsQuery.data,
    liveEventsQuery.isLoading
  );

  // State hooks for left and right photo
  const [isLeftPhotoOnTop, setIsLeftPhotoOnTop] = useState(true);
  const [photoDesktopLeft, setPhotoDesktopLeft] = useState(null);
  const [photoDesktopRight, setPhotoDesktopRight] = useState(null);

  const updateDesktopPhotos = (id) => {
    const { isEven, event } = isEvenEvent(id, curatedEvents);
    setIsLeftPhotoOnTop(!isEven);
    setPhotoDesktopLeft(
      <img src={RESOURCE_URL_PREFIX + event.image_left.path} alt="" />
    );
    setPhotoDesktopRight(
      <img src={RESOURCE_URL_PREFIX + event.image_right.path} alt="" />
    );
  };

  // This initially sets the desktop photos of the first event
  //   Additionally it preloads all desktop images
  useEffect(() => {
    if (!liveEventsQuery.isLoading && curatedEvents.length !== 0) {
      setPhotoDesktopLeft(
        <img
          src={RESOURCE_URL_PREFIX + curatedEvents[0].image_left.path}
          alt=""
        />
      );
      setPhotoDesktopRight(
        <img
          src={RESOURCE_URL_PREFIX + curatedEvents[0].image_right.path}
          alt=""
        />
      );
      if (!isMobile) {
        curatedEvents.forEach((event, i) => {
          const left = new Image();
          left.src = RESOURCE_URL_PREFIX + event.image_left.path;
          const right = new Image();
          right.src = RESOURCE_URL_PREFIX + event.image_right.path;
        });
      }
    }
  }, [
    liveEventsQuery.isLoading,
    liveEventsQuery.data,
    isMobile,
    curatedEvents,
  ]);

  // Construct photo desktop classes
  const classesPhotoDesktopLeft = cx({
    photo_desktop_left: true,
    photo_desktop_left_top: isLeftPhotoOnTop,
    photo_desktop_left_bottom: !isLeftPhotoOnTop,
  });
  const classesPhotoDesktopRight = cx({
    photo_desktop_right: true,
    photo_desktop_right_top: !isLeftPhotoOnTop,
    photo_desktop_right_bottom: isLeftPhotoOnTop,
  });

  // Construct bubble classes
  const classesBubble01 = cx({
    bubble: true,
    bubble_1: true,
  });
  const classesBubble02 = cx({
    bubble: true,
    bubble_2: true,
  });

  return (
    <div className={styles.container}>
      {/* Head Metadata */}
      <Helmet>
        <title>Annina Mossoni — Live</title>
        <meta name="description" content={SEO_DESCRIPTION} />
      </Helmet>
      {/* Only display content if data is loaded */}
      {liveEventsQuery.isLoading || liveMobilePhotoQuery.isLoading ? (
        <Loading />
      ) : (
        <div className={styles.content_container}>
          {/* Display bubbles if on desktop */}
          {isMobile ? (
            <div>
              <div className={classesBubble01}>
                <img src={imgBubbleMobile01} alt="" />
              </div>
              <div className={classesBubble02}>
                <img src={imgBubbleMobile02} alt="" />
              </div>
            </div>
          ) : (
            <div>
              <div className={classesBubble01}>
                <img src={imgBubble01} alt="" />
              </div>
              <div className={classesBubble02}>
                <img src={imgBubble02} alt="" />
              </div>
            </div>
          )}
          {/* If on mobile, show live mobile photo */}
          {isMobile ? (
            <div className={styles.mobile_photo}>
              <img
                src={RESOURCE_URL_PREFIX + liveMobilePhoto.photo.path}
                alt=""
              />
            </div>
          ) : null}
          <div className={styles.months_container}>
            {months.map((month) => {
              return (
                <Month
                  key={month.date}
                  data={month}
                  updateDesktopPhotos={updateDesktopPhotos}
                />
              );
            })}
          </div>
          {/* If on desktop, show desktop photos */}
          {isMobile ||
          photoDesktopLeft === null ||
          photoDesktopRight === null ? null : (
            <div>
              <div className={classesPhotoDesktopLeft}>{photoDesktopLeft}</div>
              <div className={classesPhotoDesktopRight}>
                {photoDesktopRight}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Live;
