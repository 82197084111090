// CSS imports
import styles from './Month.module.scss';

// 3rd party imports
import React from 'react';

// Own code imports
import Event from './Event';

// Props
// - data is expected to contain the month data
// - updateDesktopPhotos is expected to contain a function which updates the
//   the desktop photos
const Month = ({ data, updateDesktopPhotos }) => {
  return (
    <div className={styles.container}>
      <div className={styles.date}>
        <div className="font_menu">{data.date}</div>
      </div>
      <div className={styles.event_container}>
        {data.events.map((event) => {
          return (
            <Event
              key={event.id}
              data={event}
              updateDesktopPhotos={updateDesktopPhotos}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Month;
