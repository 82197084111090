// CSS imports
import styles from './Overview.module.scss';

// 3rd party imports
import React from 'react';

// Own code imports
import OverviewItem from './OverviewItem';

// Assets

// Setup

// Props
// -
const Overview = ({ projectRefs, data }) => {
  // Distribute projects to their columns
  let projectsColumn1 = [];
  let projectsColumn2 = [];
  let projectsColumn3 = [];

  const distribute = () => {
    let currentColumn = 1;
    data.forEach((project, i) => {
      // Push each project to according column
      if (currentColumn === 1) {
        projectsColumn1.push(project);
      } else if (currentColumn === 2) {
        projectsColumn2.push(project);
      } else if (currentColumn === 3) {
        projectsColumn3.push(project);
      }
      // Increase index, or reset if gt 3
      currentColumn++;
      if (currentColumn > 3) {
        currentColumn = 1;
      }
    });
  };
  distribute();

  return (
    <div className={styles.container}>
      {/* Display the projects in the overview */}
      <div className={styles.overview_container}>
        <div className={styles.column}>
          {projectsColumn1.map((project, i) => {
            const ref = projectRefs.find((ref) => ref.id === project._id);
            return (
              <div key={project._id} className={styles.overview_item}>
                <OverviewItem
                  name={project.name}
                  description={project.description}
                  posterSrc={project.poster.path}
                  projectRef={ref.ref}
                />
              </div>
            );
          })}
        </div>
        <div className={styles.column}>
          {projectsColumn2.map((project, i) => {
            const ref = projectRefs.find((ref) => ref.id === project._id);
            return (
              <div key={project._id} className={styles.overview_item}>
                <OverviewItem
                  name={project.name}
                  description={project.description}
                  posterSrc={project.poster.path}
                  projectRef={ref.ref}
                />
              </div>
            );
          })}
        </div>
        <div className={styles.column}>
          {projectsColumn3.map((project, i) => {
            const ref = projectRefs.find((ref) => ref.id === project._id);
            return (
              <div key={project._id} className={styles.overview_item}>
                <OverviewItem
                  name={project.name}
                  description={project.description}
                  posterSrc={project.poster.path}
                  projectRef={ref.ref}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Overview;
