// CSS imports
import styles from './Navigation.module.scss';

// 3rd party components import
import React, { useRef, useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import classNames from 'classnames/bind';

// Own components
import useEventListener from '../hooks/useEventListener';

// Setup
const cx = classNames.bind(styles);

const Naviagion = ({ isMobile }) => {
  // Knows where the nav is expanded or collapsed
  const [isCollapsed, setIsCollapsed] = useState(false);
  // Knows whether the nav is open or not
  const [isOpen, setIsOpen] = useState(false);
  // Get the location object to figure out which nav item should be underlined
  const location = useLocation();
  // Ref for the item_container, to figure out if it is higher than the viewport
  const itemContainerRef = useRef(null);
  // Handler to be run on mount or window resize
  const checkIfNavShouldCollapse = () => {
    // Calculate margin depending on screen
    const navMargin = isMobile
      ? window.innerWidth * 0.04
      : window.innerWidth * 0.01;
    // If the item_container plus margin is higher than the viewport, set state
    if (
      itemContainerRef.current.getBoundingClientRect().height + 2 * navMargin >
      window.innerHeight
    ) {
      if (!isCollapsed) {
        setIsCollapsed(true);
      }
    } else {
      if (isCollapsed) {
        setIsCollapsed(false);
      }
    }
  };
  // Run this function on window resize
  useEventListener('resize', checkIfNavShouldCollapse);
  // Run this function on component did mount
  useEffect(() => {
    checkIfNavShouldCollapse();
  });
  // Callback for menu button click
  const onMenuButtonClicked = () => {
    if (isOpen) {
      setIsOpen(false);
      document.body.style.overflow = '';
    } else {
      setIsOpen(true);
      document.body.style.overflow = 'hidden';
    }
  };
  // Callback for any nav item click
  const onItemClicked = () => {
    if (isOpen) {
      setIsOpen(false);
      document.body.style.overflow = '';
    }
  };

  // Construct nav item classes
  const itemNewsClasses = cx({
    item: true,
    hidden: isCollapsed && !isOpen,
    item_highlighted:
      location.pathname === '/' || location.pathname.includes('/news/'),
  });
  const itemBioClasses = cx({
    item: true,
    hidden: isCollapsed && !isOpen,
    item_highlighted: location.pathname === '/bio',
  });
  const itemLiveClasses = cx({
    item: true,
    hidden: isCollapsed && !isOpen,
    item_highlighted: location.pathname === '/live',
  });
  const itemWorkCollabClasses = cx({
    item: true,
    hidden: isCollapsed && !isOpen,
    item_highlighted: location.pathname === '/workcollab',
  });
  const itemContactClasses = cx({
    item: true,
    hidden: isCollapsed && !isOpen,
    item_highlighted: location.pathname === '/contact',
  });

  // Construct nav button classes
  const menuButtonClasses = cx({
    item: true,
    menu_button: true,
    hidden: !isCollapsed,
    white_text: isOpen,
  });

  // Construct logo classes
  const logoClasses = cx({
    logo: true,
    logo_open: isOpen,
  });

  // Construct item_container classes
  const itemContainerClasses = cx({
    item_container: !isOpen,
    item_container_open: isOpen,
  });

  return (
    <div className={styles.container}>
      <div className={logoClasses}>
        <Link to="/">
          <div className="font_menu">ANNINA MOSSONI</div>
        </Link>
      </div>
      <div className={itemContainerClasses} ref={itemContainerRef}>
        <div className={menuButtonClasses} onClick={onMenuButtonClicked}>
          <div className="font_menu">{isOpen ? 'X' : 'MENU'}</div>
        </div>
        <div className={itemNewsClasses} onClick={onItemClicked}>
          <Link to="/">
            <div className="font_menu">NEWS</div>
          </Link>
        </div>
        <div className={itemBioClasses} onClick={onItemClicked}>
          <Link to="/bio">
            <div className="font_menu">BIO</div>
          </Link>
        </div>
        <div className={itemLiveClasses} onClick={onItemClicked}>
          <Link to="/live">
            <div className="font_menu">LIVE</div>
          </Link>
        </div>
        <div className={itemWorkCollabClasses} onClick={onItemClicked}>
          <Link to="/workcollab">
            <div className="font_menu">WORK/COLLAB</div>
          </Link>
        </div>
        <div className={itemContactClasses} onClick={onItemClicked}>
          <Link to="/contact">
            <div className="font_menu">CONTACT</div>
          </Link>
        </div>
        {/* On mobile, if the nav is open, display an additional item which is
              used to take up additional space, so that the nav items appear to
              stick to the logo on the left. It can't be achieved using the
              last nav item itself as a spacer, because it would also space
              the underline away from the item, when on the contact page. */}
        {isMobile && isOpen ? <div className={styles.item_vspacer} /> : null}
      </div>
    </div>
  );
};

export default Naviagion;
