// CSS import
import styles from './FZF.module.scss';

// 3rd party components import
import React from 'react';
import { Helmet } from 'react-helmet-async';

const FourZeroFour = (props) => {
  return (
    <div className={styles.container}>
      {/* Head Metadata */}
      <Helmet>
        <title>Annina Mossoni — 404</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="font_title">Vier Null Vier</div>
      <div className="font_text">
        Die angeforderte Ressource existiert nicht (mehr).
      </div>
    </div>
  );
};

export default FourZeroFour;
