import React from 'react';
import LineWithHyphens from './LineWithHyphens';

const TextWithLinebreaks = ({ text, key_prefix }) => {
  return (
    <div>
      {text.split('\n').map((line, i) => {
        if (line === '') {
          return (
            <div key={key_prefix + '-' + i}>
              <br />
            </div>
          );
        }

        return (
          <div key={key_prefix + '-' + i}>
            <LineWithHyphens line={line} />
          </div>
        );
      })}
    </div>
  );
};

export default TextWithLinebreaks;
