// CSS import
import styles from './News.module.scss';

// 3rd party components import
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import classNames from 'classnames/bind';

// Own components
import { processNewsEntriesQuery } from './Utils';
import { SEO_DESCRIPTION } from '../../../Constants';
import Grid from './grid/Grid';
import { getNewsEntries } from '../../../API';
import Loading from '../../utils/Loading';

// Assets
import imgBubble01 from '../../../assets/bubbles/desktop/news/bubble_1.png';
import imgBubble02 from '../../../assets/bubbles/desktop/news/bubble_2.png';
import imgBubble03 from '../../../assets/bubbles/desktop/news/bubble_3.png';
import imgBubble04 from '../../../assets/bubbles/desktop/news/bubble_4.png';
import imgBubble05 from '../../../assets/bubbles/desktop/news/bubble_5.png';
import imgBubble06 from '../../../assets/bubbles/desktop/news/bubble_6.png';
import imgBubbleMobile01 from '../../../assets/bubbles/mobile/news/bubble_1.png';
import imgBubbleMobile02 from '../../../assets/bubbles/mobile/news/bubble_2.png';

// Setup
const cx = classNames.bind(styles);

const News = ({ isMobile }) => {
  const dataQuery = useQuery('news-entries', getNewsEntries);
  const data = processNewsEntriesQuery(dataQuery);

  // Construct bubble classes
  const classesBubble01 = cx({
    bubble: true,
    bubble_1: true,
  });
  const classesBubble02 = cx({
    bubble: true,
    bubble_2: true,
  });
  const classesBubble03 = cx({
    bubble: true,
    bubble_3: true,
  });
  const classesBubble04 = cx({
    bubble: true,
    bubble_4: true,
  });
  const classesBubble05 = cx({
    bubble: true,
    bubble_5: true,
  });
  const classesBubble06 = cx({
    bubble: true,
    bubble_6: true,
  });

  return (
    <div className={styles.container}>
      {/* Head Metadata */}
      <Helmet>
        <title>Annina Mossoni — News</title>
        <meta name="description" content={SEO_DESCRIPTION} />
      </Helmet>

      {/* Only render content if data is loaded */}
      {dataQuery.isLoading ? (
        <Loading />
      ) : (
        <div>
          {/* Render appropriate bubbles */}
          {isMobile ? (
            <div>
              {/* Mobile */}
              <div className={classesBubble01}>
                <img src={imgBubbleMobile01} alt="" />
              </div>
              <div className={classesBubble02}>
                <img src={imgBubbleMobile02} alt="" />
              </div>
            </div>
          ) : (
            <div>
              {/* Desktop */}
              <div className={classesBubble01}>
                <img src={imgBubble01} alt="" />
              </div>
              <div className={classesBubble02}>
                <img src={imgBubble02} alt="" />
              </div>
              <div className={classesBubble03}>
                <img src={imgBubble03} alt="" />
              </div>
              <div className={classesBubble04}>
                <img src={imgBubble04} alt="" />
              </div>
              <div className={classesBubble05}>
                <img src={imgBubble05} alt="" />
              </div>
              <div className={classesBubble06}>
                <img src={imgBubble06} alt="" />
              </div>
            </div>
          )}
          <Grid data={data} hideElement={null} isMobile={isMobile} />
        </div>
      )}
    </div>
  );
};

export default News;
