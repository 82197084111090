// CSS imports
import styles from './Mobile.module.scss';

// 3rd party imports
import React from 'react';

// Own code imports
import PhotoOrVideo from '../../../utils/PhotoOrVideo';
import MiniGridMobile from './minigrid/MiniGridMobile';
import TextWithLinebreaks from '../../../utils/TextWithLinebreaks';
import { RESOURCE_URL_PREFIX } from '../../../../Constants';

// Assets

// Setup

// Props
// - data is expected to have value
const Mobile = ({ data, isFirst }) => {
  return (
    <div className={styles.container}>
      {/* If it's the first project, display poster here */}
      {isFirst ? (
        <div className={styles.poster_first}>
          <img src={RESOURCE_URL_PREFIX + data.poster.path} alt="" />{' '}
        </div>
      ) : null}

      {/* Project name & description */}
      <div className={styles.name_container}>
        <div className={styles.description}>
          <div className="font_text">{data.description}</div>
        </div>
        <div className={styles.name}>
          <div className="font_title">{data.name}</div>
        </div>
      </div>

      {/* If it's not the first project, display poster here */}
      {isFirst ? null : (
        <div className={styles.poster}>
          <img src={RESOURCE_URL_PREFIX + data.poster.path} alt="" />{' '}
        </div>
      )}

      {/* All the text */}
      <div className={styles.text_container}>
        <div className={styles.members_container}>
          {/* Display each member */}
          {data.members.map((member, i) => {
            return (
              <div key={data._id + '-member-' + i} className={styles.member}>
                <div className={styles.member_name}>
                  <div className="font_text">{member.value.name}</div>
                </div>
                <div className={styles.member_role}>
                  <div className="font_text">{member.value.role}</div>
                </div>
              </div>
            );
          })}
        </div>
        {/* Display the text */}
        <div className={styles.text}>
          <div className="font_text">
            <TextWithLinebreaks
              text={data.text}
              key_prefix={data._id + '-text-line'}
            />
          </div>
        </div>
        {/* Display each link, if there are any links */}
        {data.links === null || data.links.length === 0 ? null : (
          <div className={styles.links_container}>
            {data.links.map((link, i) => {
              return (
                <div key={data._id + '-link-' + i} className={styles.link}>
                  <div className="font_text">
                    <a href={link.value.url} target="_blank" rel="noreferrer">
                      {link.value.text}
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {/* All the media */}
      <div className={styles.media_container}>
        {/* If there is at least an emphasized photo */}
        {data.emphasized_photo === null ||
        data.emphasized_photo.length === 0 ? null : (
          <div className={styles.media_emphasized}>
            <PhotoOrVideo
              photo={data.emphasized_photo.path}
              video={data.emphasized_video}
            />
          </div>
        )}
        {/* If there is at least one photo */}
        {data.photos === null || data.photos.length === 0 ? null : (
          <MiniGridMobile data={data} />
        )}
      </div>
    </div>
  );
};

export default Mobile;
