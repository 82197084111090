import { API_URL } from './Constants';

// Fetch data for the 'Bio' page
export async function getBioData() {
  const response = await fetch(API_URL + 'singletons/get/bio');
  return response.json();
}

// Fetch the photo src to be displayed on Contact mobile
export async function getContactMobilePhoto() {
  const response = await fetch(API_URL + 'singletons/get/contact_mobile_photo');
  return response.json();
}

// Fetch all contact links
export async function getContactLinks() {
  const response = await fetch(API_URL + 'collections/get/contact_links', {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      simple: true,
    }),
  });
  return response.json();
}

// Fetch the photo src to be displayed on Live mobile
export async function getLiveMobilePhoto() {
  const response = await fetch(API_URL + 'singletons/get/live_image_mobile');
  return response.json();
}

// Fetch all live events
export async function getLiveEvents() {
  const response = await fetch(API_URL + 'collections/get/live_events', {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      simple: true,
      // -1 means newest first, 1 means oldest first
      sort: { date: 1 },
    }),
  });
  return response.json();
}

// Fetch all news entries
export async function getNewsEntries() {
  const response = await fetch(API_URL + 'collections/get/news_entries', {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      simple: true,
    }),
  });
  return response.json();
}

// Fetch all projects
export async function getProjects() {
  const response = await fetch(
    API_URL + 'collections/get/work_collab_projects',
    {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        simple: true,
      }),
    }
  );
  return response.json();
}
