// 3rd party imports
import { useState, useEffect } from 'react';

// Processes the mobile photo query
export const processLiveMobilePhotoQuery = (query) => {
  const extractLiveMobilePhoto = () => {
    // Check the current state of the query
    if (query.isLoading) return 'Loading...';
    if (query.error)
      return 'Error fetching data from backend: ' + query.error.message;
    // Delete unnecessary keys in the data object
    delete query.data['_by'];
    delete query.data['_mby'];
    // Then return it
    return query.data;
  };
  return extractLiveMobilePhoto();
};

// Takes a date string and returns the proper month name
function getFormattedMonth(dateString) {
  let dateObj = new Date(dateString);
  const months = [
    'JANUAR',
    'FEBRUAR',
    'MÄRZ',
    'APRIL',
    'MAI',
    'JUNI',
    'JULI',
    'AUGUST',
    'SEPTEMBER',
    'OKTOBER',
    'NOVEMBER',
    'DEZEMBER',
  ];
  const month = months[dateObj.getMonth()];
  return month;
}

export const useMonths = (events, isLoading) => {
  const [months, setMonths] = useState({
    months: [],
    curatedEvents: [],
  });

  useEffect(() => {
    if (!isLoading) {
      setMonths(computeMonths(events));
    }
  }, [events, isLoading]);

  return months;
};

const computeMonths = (eventsRaw) => {
  let events = [];
  let curatedEvents = [];
  let months = [];

  // Restructure array
  events = eventsRaw.map((event) => {
    return {
      id: event._id,
      name: event.name,
      description: event.description,
      date: new Date(event.date),
      link: event.link,
      image_left: event.image_left,
      image_right: event.image_right,
    };
  });

  events.forEach((event, i) => {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    if (event.date >= today) {
      // Only sort upcoming shows, drop passed ones
      sortShowInMonth(months, event);
      curatedEvents.push(event);
    }
  });

  return {
    months: months,
    curatedEvents: curatedEvents,
  };
};

const sortShowInMonth = (months, event) => {
  const monthString = constructMonthString(event);
  let sorted = false;
  months.forEach((month, i) => {
    if (month.date === monthString) {
      month.events.push(event);
      sorted = true;
      return;
    }
  });
  if (!sorted) {
    months.push({
      date: monthString,
      events: [event],
    });
  }
};

const constructMonthString = (event) => {
  return getFormattedMonth(event.date) + ' ' + event.date.getFullYear();
};

// Takes an event id and an array of events and checks if it's got an even
//   or an odd index (starting at 1)
export const isEvenEvent = (id, events) => {
  let events_copy = events.map((event) => event);
  events_copy.sort((a, b) => {
    return a.date > b.date;
  });
  let isEven = false;
  for (let i = 0; i < events_copy.length; i++) {
    if (events_copy[i].id === id) {
      if ((i + 1) % 2 === 0) {
        isEven = true;
        break;
      }
    }
  }
  return {
    isEven: isEven,
    event: events_copy.find((e) => e.id === id),
  };
};
