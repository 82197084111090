import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const LineWithHyphens = ({ line }) => {
  const parts = line.split('&shy;');
  let renderLine = [];
  parts.forEach((part, i) => {
    renderLine.push(part);
    if (i < parts.length - 1) {
      renderLine.push(<span key={uuidv4()}>&shy;</span>);
    }
  });
  return <>{renderLine}</>;
};

export default LineWithHyphens;
