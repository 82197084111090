// CSS import
import styles from './Grid.module.scss';

// 3rd party components import
import React from 'react';

// Own components
import Mobile from './Mobile';
import Desktop from './Desktop';

const Grid = ({ data, hideElement, isMobile }) => {
  return (
    <div className={styles.container}>
      {isMobile ? (
        <Mobile data={data} hideElement={hideElement} isMobile={isMobile} />
      ) : (
        <Desktop data={data} hideElement={hideElement} isMobile={isMobile} />
      )}
    </div>
  );
};

export default Grid;
