export const processProjectsQuery = (query) => {
  const extractProjects = () => {
    // Check the current state of the query
    if (query.isLoading) return 'Loading...';
    if (query.error)
      return 'Error fetching data from backend: ' + query.error.message;
    query.data.forEach((item, i) => {
      // Delete unnecessary keys in the data object
      delete item['_by'];
      delete item['_mby'];
      delete item['_modified'];
      delete item['_created'];
    });
    // Then return it
    return query.data;
  };
  return extractProjects();
};
