// CSS imports
import styles from './Bio.module.scss';

// 3rd party components import
import React from 'react';
import { useQuery } from 'react-query';
import { Helmet } from 'react-helmet-async';

// Own components
import { processBioDataQuery } from './Utils';
import { getBioData } from '../../../API';
import { SEO_DESCRIPTION, BREAKPOINT_PHONE_MAX } from '../../../Constants';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Mobile from './Mobile';
import Desktop from './Desktop';
import Loading from '../../utils/Loading';

const Bio = (props) => {
  // Get data
  const dataQuery = useQuery('bio-data', getBioData);
  const data = processBioDataQuery(dataQuery);

  // Check if on desktop or mobile
  const isMobile = useMediaQuery('(max-width: ' + BREAKPOINT_PHONE_MAX + 'px)');

  return (
    <div className={styles.container}>
      {/* Head Metadata */}
      <Helmet>
        <title>Annina Mossoni — Bio</title>
        <meta name="description" content={SEO_DESCRIPTION} />
      </Helmet>
      {/* Only display content if data is loaded */}
      {dataQuery.isLoading ? (
        <Loading />
      ) : (
        <div>
          {/* Display appropriate sub-component based on environment */}
          {isMobile ? <Mobile data={data} /> : <Desktop data={data} />}
        </div>
      )}
    </div>
  );
};

export default Bio;
