// CSS imports
import styles from './GridElement.module.scss';

// 3rd party component imports
import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';

// Own components
import { RESOURCE_URL_PREFIX } from '../../../../../Constants';
import TextWithLinebreaks from '../../../../utils/TextWithLinebreaks';

// Setup
const cx = classNames.bind(styles);

// Props
// - isFullEntry is expected to have value
// - posterSrc is expected to have value
// - hoverText is expected to be either null, empty or have value
// - entryId is expected to have value
// - external_link is expected to be either null, empty or have value
const GridElement = ({
  isFullEntry,
  posterSrc,
  hoverText,
  entryId,
  externalLink,
  isMobile,
}) => {
  // Hook into the router history
  const history = useHistory();
  // Knows whether to show the hover text or not
  const [showHoverText, setShowHoverText] = useState(false);

  // Construct classes
  const posterClasses = cx({
    poster: true,
    pointer:
      (isFullEntry && !isMobile) ||
      (externalLink !== null && externalLink !== '' && !isMobile),
  });

  // MouseEnter EventHandler
  const handleMouseEnter = () => {
    // If on desktop
    if (!isMobile) {
      // And hovertext is not null or empty
      if (hoverText !== null && hoverText !== '') {
        // And it's not already shown
        if (!showHoverText) {
          // Show it
          setShowHoverText(true);
        }
      }
    }
  };
  // MouseLeave EventHandler
  const handleMouseLeave = () => {
    if (showHoverText) {
      setShowHoverText(false);
    }
  };
  // MouseClicked EventHandler
  const handleMouseClicked = () => {
    if (isFullEntry) {
      history.push('/news/' + entryId);
    } else {
      if (externalLink !== null && externalLink !== '') {
        window.open(externalLink);
      }
    }
  };

  // Construct divs
  let divHoverText = null;
  if (showHoverText) {
    divHoverText = (
      <div className={styles.hover_text}>
        <div className="font_menu">
          <TextWithLinebreaks
            text={hoverText}
            key_prefix={'news-hover-text-' + entryId}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className={styles.container}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseClicked}
    >
      <div className={posterClasses}>
        <img src={RESOURCE_URL_PREFIX + posterSrc} alt="" />
        {divHoverText}
      </div>
    </div>
  );
};

export default GridElement;
