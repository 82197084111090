// CSS imports
import styles from './Mobile.module.scss';

// 3rd party components import
import React, { useState } from 'react';
import classNames from 'classnames/bind';

// Own components
import Grid from '../grid/Grid';
import { RESOURCE_URL_PREFIX } from '../../../../Constants';
import ExternalVideo from '../../../utils/ExternalVideo';
import TextWithLinebreaks from '../../../utils/TextWithLinebreaks';

// Assets
import imgBubble01 from '../../../../assets/bubbles/mobile/news_entry/bubble_1.png';
import imgBubble02 from '../../../../assets/bubbles/mobile/news_entry/bubble_2.png';

// Setup
const cx = classNames.bind(styles);

const Mobile = ({ data, entryId, isMobile }) => {
  const [aspectRatioPoster, setAspectRatioPoster] = useState(1.333);
  const [showVideo, setShowVideo] = useState(false);
  // Get the entry which we are interested in out of the data array
  const entry = data.find((entry) => entry._id === entryId);
  // Check keys which can be null
  const subtitle =
    entry.subtitle === null || entry.subtitle === ''
      ? 'UNTERTITEL FEHLT IM BACKEND'
      : entry.subtitle;
  const entryText =
    entry.content_text === null || entry.content_text === ''
      ? 'TEXT FEHLT IM BACKEND'
      : entry.content_text;

  // Event handlers
  const handleOnPosterLoad = ({ target: img }) => {
    setAspectRatioPoster(img.width / img.height);
  };
  const handlePlayOverlayClicked = () => {
    setShowVideo(true);
  };

  // Construct classnames
  const classesBubble01 = cx({
    bubble: true,
    bubble_1: true,
  });
  const classesBubble02 = cx({
    bubble: true,
    bubble_2: true,
  });

  return (
    <div className={styles.container}>
      {/* Bubbles */}
      <div className={classesBubble01}>
        <img src={imgBubble01} alt="" />
      </div>
      <div className={classesBubble02}>
        <img src={imgBubble02} alt="" />
      </div>
      {/* Entry itself */}
      <div className={styles.entry_container}>
        <div className={styles.poster}>
          {showVideo ? (
            <ExternalVideo
              url={entry.video_url}
              aspectRatio={aspectRatioPoster}
            />
          ) : (
            <div>
              <img
                onLoad={handleOnPosterLoad}
                src={RESOURCE_URL_PREFIX + entry.poster.path}
                alt=""
              />
              {entry.video_url !== null && entry.video_url !== '' ? (
                <div
                  onClick={handlePlayOverlayClicked}
                  className={styles.play_overlay}
                >
                  <svg id="triangle" viewBox="0 0 85 100">
                    <polygon points="0 0, 85 50, 0 100" />
                  </svg>
                </div>
              ) : null}
            </div>
          )}
        </div>
        <div className={styles.subtitle}>
          <div className="font_text">{subtitle}</div>
        </div>
        <div className={styles.title}>
          <div className="font_title">{entry.title}</div>
        </div>
        <div className={styles.text}>
          <div className="font_text">
            <TextWithLinebreaks
              text={entryText}
              key_prefix={entryId + '-text-line'}
            />
          </div>
        </div>
        {/* Display the links if the array is not null and not empty */}
        {entry.content_links !== null && entry.content_links.length > 0 ? (
          <div className={styles.links_container}>
            {entry.content_links.map((link, i) => {
              return (
                <div key={'link-' + i} className={styles.link}>
                  <div className="font_text">
                    <a href={link.value.url} target="_blank" rel="noreferrer">
                      {link.value.text}
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
      <div className={styles.more_news_container}>
        <div className="font_menu">MEHR NEWS</div>
      </div>
      {/* Grid */}
      <Grid data={data} hideElement={entryId} isMobile={isMobile} />
    </div>
  );
};

export default Mobile;
