// CSS imports
import styles from './Desktop.module.scss';

// 3rd party imports
import React, { createRef } from 'react';
import classNames from 'classnames/bind';

// Own code imports
import Overview from './Overview';
import ProjectDesktop from '../project/Desktop';

// Assets
import imgBubble01 from '../../../../assets/bubbles/desktop/work_collab/bubble_1.png';
import imgBubble02 from '../../../../assets/bubbles/desktop/work_collab/bubble_2.png';
import imgBubble03 from '../../../../assets/bubbles/desktop/work_collab/bubble_3.png';

// Setup
const cx = classNames.bind(styles);

// Props
// - data is expected to contain the projects
const Desktop = ({ data }) => {
  // Create refs
  const projectRefs = data.map(({ _id }) => ({
    id: _id,
    ref: createRef(),
  }));

  // Construct bubble classes
  const classesBubble01 = cx({
    bubble: true,
    bubble_1: true,
  });
  const classesBubble02 = cx({
    bubble: true,
    bubble_2: true,
  });
  const classesBubble03 = cx({
    bubble: true,
    bubble_3: true,
  });

  return (
    <div className={styles.container}>
      {/* Display bubbles */}
      <div className={classesBubble01}>
        <img src={imgBubble01} alt="" />
      </div>
      <div className={classesBubble02}>
        <img src={imgBubble02} alt="" />
      </div>
      <div className={classesBubble03}>
        <img src={imgBubble03} alt="" />
      </div>
      {/* Display the project overview */}
      <Overview projectRefs={projectRefs} data={data} />
      {/* Display the projects */}
      <div className={styles.projects_container}>
        {data.map((project, i) => {
          const ref = projectRefs.find((ref) => ref.id === project._id);
          return (
            <ProjectDesktop
              key={project._id}
              projectRef={ref.ref}
              data={project}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Desktop;
