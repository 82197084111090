// CSS imports
import styles from './Desktop.module.scss';

// 3rd party imports
import React from 'react';

// Own code imports
import PhotoOrVideo from '../../../utils/PhotoOrVideo';
import MiniGridDesktop from './minigrid/MiniGridDesktop';
import TextWithLinebreaks from '../../../utils/TextWithLinebreaks';

// Assets

// Setup

// Props
// - data is expected to have value
const Desktop = ({ data, projectRef }) => {
  // div for the media container (photos and videos)
  const divMediaContainer = (
    <div className={styles.media_container}>
      {/* If there is at least an emphasized photo */}
      {data.emphasized_photo === null ||
      data.emphasized_photo.length === 0 ? null : (
        <div className={styles.media_emphasized}>
          <PhotoOrVideo
            photo={data.emphasized_photo.path}
            video={data.emphasized_video}
          />
        </div>
      )}
      {/* If there is at least one photo */}
      {data.photos === null || data.photos.length === 0 ? null : (
        <MiniGridDesktop data={data} />
      )}
    </div>
  );

  // div for the text container
  const divTextContainer = (
    <div className={styles.text_container}>
      <div className={styles.members_container}>
        {/* Display each member */}
        {data.members.map((member, i) => {
          return (
            <div key={data._id + '-member-' + i} className={styles.member}>
              <div className={styles.member_name}>
                <div className="font_text">{member.value.name}</div>
              </div>
              <div className={styles.member_role}>
                <div className="font_text">{member.value.role}</div>
              </div>
            </div>
          );
        })}
      </div>
      {/* Display the text */}
      <div className={styles.text}>
        <div className="font_text">
          <TextWithLinebreaks
            text={data.text}
            key_prefix={data._id + '-text-line'}
          />
        </div>
      </div>
      {/* Display each link, if there are any links */}
      {data.links === null || data.links.length === 0 ? null : (
        <div className={styles.links_container}>
          {data.links.map((link, i) => {
            return (
              <div key={data._id + '-link-' + i} className={styles.link}>
                <div className="font_text">
                  <a href={link.value.url} target="_blank" rel="noreferrer">
                    {link.value.text}
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );

  return (
    <div ref={projectRef} className={styles.container}>
      <div className={styles.name_container}>
        <div className={styles.description}>
          <div className="font_text">{data.description}</div>
        </div>
        <div className={styles.name}>
          <div className="font_title">{data.name}</div>
        </div>
      </div>
      <div className={styles.content_container}>
        {/* Render media and text according to 'text_location' */}
        {data.text_location === 'Links' ? divTextContainer : divMediaContainer}
        {/* Space the two things */}
        <div className={styles.vspacer} />
        {data.text_location === 'Links' ? divMediaContainer : divTextContainer}
      </div>
    </div>
  );
};

export default Desktop;
