// CSS imports
import styles from './MiniGridDesktop.module.scss';

// 3rd party components import
import React from 'react';

// Own components
import PhotoOrVideo from '../../../../utils/PhotoOrVideo';

// Props
// - data is expected to have be loaded, however not all fields must contain
//     something.
// - hideElement is expected to be either 'null' or an id of an element
const Desktop = ({ data }) => {
  // Distribute entries to their columns
  let entriesColumn1 = [];
  let entriesColumn2 = [];
  data.photos.forEach((item) => {
    if (item.value.column === 'Spalte 1') {
      entriesColumn1.push(item);
    } else {
      entriesColumn2.push(item);
    }
  });
  // Sort entries inside their columns
  entriesColumn1.sort((a, b) => {
    return a.order - b.order;
  });
  entriesColumn2.sort((a, b) => {
    return a.order - b.order;
  });

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        {entriesColumn1.map((item, i) => {
          if (item.value.photo === null || item.value.photo.length === 0) {
            return null;
          }
          return (
            <div
              key={data._id + '-column-1-griditem-' + i}
              className={styles.item}
            >
              <PhotoOrVideo
                photo={item.value.photo.path}
                video={item.value.video_url}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.column}>
        {entriesColumn2.map((item, i) => {
          if (item.value.photo === null || item.value.photo.length === 0) {
            return null;
          }
          return (
            <div
              key={data._id + '-column-2-griditem-' + i}
              className={styles.item}
            >
              <PhotoOrVideo
                photo={item.value.photo.path}
                video={item.value.video_url}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Desktop;
