// CSS imports
import styles from './MiniGridMobile.module.scss';

// 3rd party components import
import React from 'react';

// Own components
import PhotoOrVideo from '../../../../utils/PhotoOrVideo';

// Props
// - data is expected to have be loaded, however not all fields must contain
//     something.
// - hideElement is expected to be either 'null' or an id of an element
const MiniGridMobile = ({ data }) => {
  // Distribute entries to their columns
  let items = [];
  data.photos.forEach((item) => {
    if (item.value.show_on_mobile) {
      items.push(item);
    }
  });
  // Sort it
  items.sort((a, b) => {
    // If the the order is equal (both entries are the first in their columns)
    if (a.order === b.order) {
      // The item of the smaller column (1<2) comes first
      if (a.column < b.column) {
        return -1;
      } else if (a.column > b.column) {
        return 1;
      }
    }
    // If the order is not equal, the item with the smaller (1<2) order comes first
    return a.order - b.order;
  });

  return (
    <div className={styles.container}>
      {items.map((item, i) => {
        if (item.value.photo === null || item.value.photo.length === 0) {
          return null;
        }
        return (
          <div
            key={data._id + '-column-1-griditem-' + i}
            className={styles.item}
          >
            <PhotoOrVideo
              photo={item.value.photo.path}
              video={item.value.video_url}
            />
          </div>
        );
      })}
    </div>
  );
};

export default MiniGridMobile;
