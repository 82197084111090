// CSS imports
import styles from './OverviewItem.module.scss';

// 3rd party imports
import React from 'react';

// Own code imports
import { RESOURCE_URL_PREFIX } from '../../../../Constants';

// Props
// - All props are expected to have value
const OverviewItem = ({ name, description, posterSrc, projectRef }) => {
  return (
    <div
      onClick={() => {
        // Define offset as 0.65vw
        const yOffset = -((window.innerWidth / 100) * 0.65);
        // Calculate scroll position
        const y =
          projectRef.current.getBoundingClientRect().top +
          window.pageYOffset +
          yOffset;
        // And finally scroll there smoothly
        window.scrollTo({ top: y, behavior: 'smooth' });
      }}
      className={styles.container}
    >
      <img src={RESOURCE_URL_PREFIX + posterSrc} alt="" />
      <div className={styles.info_overlay}>
        <div className={styles.description}>
          <div className="font_text">{description}</div>
        </div>
        <div className={styles.name}>
          <div className="font_title">{name}</div>
        </div>
      </div>
    </div>
  );
};

export default OverviewItem;
