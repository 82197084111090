// CSS imports
import styles from './Event.module.scss';

// 3rd party imports
import React from 'react';

// Own code imports
import LineWithHyphens from '../../../utils/LineWithHyphens';

// Props
// - data is expected to contain the month data
// - updateDesktopPhotos is expected to contain a function which updates the
//   the desktop photos
const Event = ({ data, updateDesktopPhotos }) => {
  const event = (
    <div
      onMouseEnter={() => {
        updateDesktopPhotos(data.id);
      }}
      className={styles.content_container}
    >
      <div className={styles.day}>
        <div className="font_title">
          {('0' + data.date.getDate()).slice(-2)}
        </div>
      </div>
      <div className={styles.name_description_container}>
        <div className={styles.name}>
          <div className="font_title">{data.name}</div>
        </div>
        <div className={styles.description}>
          <div className="font_text">
            <LineWithHyphens line={data.description} />
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div className={styles.container}>
      {data.link !== null && data.link.length !== '' ? (
        <a href={data.link} target="_blank" rel="noreferrer">
          {event}
        </a>
      ) : (
        <>{event}</>
      )}
    </div>
  );
};

export default Event;
