// CSS import
import styles from './Entry.module.scss';

// 3rd party components import
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Helmet } from 'react-helmet-async';

// Own components
import { SEO_DESCRIPTION } from '../../../../Constants';
import Mobile from './Mobile';
import Desktop from './Desktop';
import FourZeroFour from '../../404/FZF';
import { getNewsEntries } from '../../../../API';
import { processNewsEntriesQuery } from './../Utils';
import Loading from '../../../utils/Loading';

const Entry = ({ match, isMobile }) => {
  // Knows whether this entry exists or not
  const [entryExists, setEntryExists] = useState(null);
  // Get news entry data
  const dataQuery = useQuery('news-entries', getNewsEntries);
  const data = processNewsEntriesQuery(dataQuery);
  // Figure out which entry to show. This uses the match prop passed by
  //   react-router-dom and destructures it to figure out news entry id.
  const {
    params: { entryId },
  } = match;

  // Checks if a news entry exists for this id.
  const checkIfEntryExists = () => {
    for (let i = 0; i < data.length; i++) {
      if (data[i]._id === entryId && data[i].is_full_entry) {
        setEntryExists(true);
        return;
      }
    }
    setEntryExists(false);
  };
  // Calls the above function if it was not already called and only if data is
  //   loaded.
  if (!dataQuery.isLoading) {
    if (entryExists === null) {
      checkIfEntryExists();
    }
  }

  return (
    <div className={styles.container}>
      {/* Head Metadata */}
      <Helmet>
        <title>Annina Mossoni — News</title>
        <meta name="description" content={SEO_DESCRIPTION} />
      </Helmet>
      {/* Only render content if data is loaded */}
      {dataQuery.isLoading ? (
        <Loading />
      ) : (
        <div>
          {entryExists ? (
            <div>
              {isMobile ? (
                <Mobile data={data} entryId={entryId} isMobile={isMobile} />
              ) : (
                <Desktop data={data} entryId={entryId} isMobile={isMobile} />
              )}
            </div>
          ) : (
            <div>
              <FourZeroFour />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Entry;
