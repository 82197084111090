// CSS imports
import styles from './Mobile.module.scss';

// 3rd party imports
import React from 'react';
import classNames from 'classnames/bind';

// Own code imports
import ProjectMobile from '../project/Mobile';

// Assets
import imgBubble01 from '../../../../assets/bubbles/mobile/work_collab/bubble_1.png';
import imgBubble02 from '../../../../assets/bubbles/mobile/work_collab/bubble_2.png';

// Setup
const cx = classNames.bind(styles);

// Props
// -
const Mobile = ({ data }) => {
  // Construct bubble classes
  const classesBubble01 = cx({
    bubble: true,
    bubble_1: true,
  });
  const classesBubble02 = cx({
    bubble: true,
    bubble_2: true,
  });

  return (
    <div className={styles.container}>
      {/* Display bubbles */}
      <div className={classesBubble01}>
        <img src={imgBubble01} alt="" />
      </div>
      <div className={classesBubble02}>
        <img src={imgBubble02} alt="" />
      </div>
      {/* Display the projects */}
      <div className={styles.projects_container}>
        {data.map((project, i) => {
          const isFirst = i === 0;
          return (
            <ProjectMobile key={project._id} data={project} isFirst={isFirst} />
          );
        })}
      </div>
    </div>
  );
};

export default Mobile;
