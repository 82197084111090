export const processBioDataQuery = (query) => {
  const extractBioData = () => {
    // Check the current state of the query
    if (query.isLoading) return 'Loading...';
    if (query.error)
      return 'Error fetching data from backend: ' + query.error.message;
    // Delete unnecessary keys in the data object
    delete query.data['_by'];
    delete query.data['_mby'];
    // Then return it
    return query.data;
  };
  return extractBioData();
};
