// CSS imports
import styles from './App.module.scss';
import './custom_scrollbars.scss';

// 3rd party components import
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HelmetProvider } from 'react-helmet-async';

// Own components
import Navigation from './components/Navigation';
import News from './components/sites/news/News';
import NewsEntry from './components/sites/news/entry/Entry';
import Bio from './components/sites/bio/Bio';
import Live from './components/sites/live/Live';
import WorkCollab from './components/sites/work_collab/WorkCollab';
import Contact from './components/sites/contact/Contact';
import FourZeroFour from './components/sites/404/FZF';
import ScrollToTop from './components/utils/ScrollToTop';
import useMediaQuery from './hooks/useMediaQuery';
import {
  getBioData,
  getNewsEntries,
  getProjects,
  getContactMobilePhoto,
  getContactLinks,
  getLiveMobilePhoto,
  getLiveEvents,
} from './API';
import { BREAKPOINT_PHONE_MAX } from './Constants';

// react-query setup

// Create query client and set data to be kept in cache forever
//   (as in for the whole session)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

// Prefetch queries on first load
const prefetchQueries = async () => {
  await queryClient.prefetchQuery('news-entries', getNewsEntries);
  await queryClient.prefetchQuery('bio-data', getBioData);
  await queryClient.prefetchQuery('live-mobile-photo', getLiveMobilePhoto);
  await queryClient.prefetchQuery('live-events', getLiveEvents);
  await queryClient.prefetchQuery('projects', getProjects);
  await queryClient.prefetchQuery(
    'contact-mobile-photo',
    getContactMobilePhoto
  );
  await queryClient.prefetchQuery('contact-links', getContactLinks);
};
prefetchQueries();

function App() {
  // Check if on desktop or mobile
  const isMobile = useMediaQuery('(max-width: ' + BREAKPOINT_PHONE_MAX + 'px)');

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <div className={styles.container}>
          <Router>
            {/* On each route change, scroll to top */}
            <ScrollToTop />
            {/* Always display navigation */}
            <Navigation isMobile={isMobile} />
            {/* But only show content relevant to current route */}
            <div className={styles.content}>
              <Switch>
                <Route exact path="/">
                  <News isMobile={isMobile} />
                </Route>
                {/* The key prop id key here - it makes sure the component gets
                  remounted when the entryId changes. Only in that way the
                  state gets reinizialized. */}
                <Route
                  path="/news/:entryId"
                  render={({ match }) => (
                    <NewsEntry
                      key={match.params.entryId}
                      match={match}
                      isMobile={isMobile}
                    />
                  )}
                />
                <Route path="/bio">
                  <Bio isMobile={isMobile} />
                </Route>
                <Route path="/live">
                  <Live isMobile={isMobile} />
                </Route>
                <Route path="/workcollab">
                  <WorkCollab isMobile={isMobile} />
                </Route>
                <Route path="/contact">
                  <Contact isMobile={isMobile} />
                </Route>
                <Route>
                  <FourZeroFour isMobile={isMobile} />
                </Route>
              </Switch>
            </div>
          </Router>
        </div>
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;
