// CSS imports
import styles from './Mobile.module.scss';

// 3rd party components import
import React from 'react';

// Own components
import GridElement from './grid_element/GridElement';

// Props
// - data is expected to have be loaded, however not all fields must contain
//     something.
// - hideElement is expected to be either 'null' or an id of an element
const Mobile = ({ data, hideElement, isMobile }) => {
  // Create a local shallow copy of the array, omitting the 'hideElement'
  let entries = [];
  data.forEach((item, i) => {
    if (hideElement !== item._id) {
      entries.push(item);
    }
  });
  // Sort it
  entries.sort((a, b) => {
    // If the the order is equal (both entries are the first in their columns)
    if (a.order === b.order) {
      // The item of the smaller column (1<2) comes first
      if (a.column < b.column) {
        return -1;
      } else {
        return 1;
      }
    }
    // If the order is not equal, the item with the smaller (1<2) order comes first
    return a.order - b.order;
  });

  return (
    <div className={styles.container}>
      {entries.map((entry) => {
        return (
          <div key={entry._id} className={styles.entry}>
            <GridElement
              isFullEntry={entry.is_full_entry}
              posterSrc={entry.poster.path}
              hoverText={entry.hover_text}
              entryId={entry._id}
              externalLink={entry.external_link}
              isMobile={isMobile}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Mobile;
