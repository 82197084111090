// CSS imports
import styles from './ExternalVideo.module.scss';

// 3rd party components import
import React, { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player/lazy';

// Own components
import useEventListener from '../../hooks/useEventListener';

const ExternalVideo = ({ url, aspectRatio }) => {
  const divRef = useRef(null);
  const [height, setHeight] = useState(600);
  // Resized the video appropriately
  const handleWindowResized = () => {
    setHeight(divRef.current.clientWidth / aspectRatio);
  };
  // Above function gets called on window resize
  useEventListener('resize', handleWindowResized);
  // As well as on component mount
  useEffect(() => {
    handleWindowResized();
  });
  return (
    <div className={styles.container} ref={divRef}>
      <ReactPlayer
        width="100%"
        height={height}
        playing
        controls
        url={url}
        config={{
          youtube: {
            playerVars: {
              playsinline: 0,
              modestbranding: 1,
            },
          },
        }}
      />
    </div>
  );
};

export default ExternalVideo;
