// CSS imports
import styles from './Desktop.module.scss';

// 3rd party components import
import React from 'react';

// Own components
import GridElement from './grid_element/GridElement';

// Props
// - data is expected to have be loaded, however not all fields must contain
//     something.
// - hideElement is expected to be either 'null' or an id of an element
const Desktop = ({ data, hideElement, isMobile }) => {
  // Distribute entries to their columns
  let entriesColumn1 = [];
  let entriesColumn2 = [];
  let entriesColumn3 = [];
  data.forEach((item, i) => {
    switch (item.column) {
      case 'Spalte 1':
        if (hideElement !== item._id) {
          entriesColumn1.push(item);
        }
        break;
      case 'Spalte 2':
        if (hideElement !== item._id) {
          entriesColumn2.push(item);
        }
        break;
      case 'Spalte 3':
        if (hideElement !== item._id) {
          entriesColumn3.push(item);
        }
        break;
      default:
        break;
    }
  });
  // Sort entries inside their columns
  entriesColumn1.sort((a, b) => {
    return a.order - b.order;
  });
  entriesColumn2.sort((a, b) => {
    return a.order - b.order;
  });
  entriesColumn3.sort((a, b) => {
    return a.order - b.order;
  });
  return (
    <div className={styles.container}>
      <div className={styles.column}>
        {entriesColumn1.map((entry) => {
          return (
            <div key={entry._id} className={styles.entry}>
              <GridElement
                isFullEntry={entry.is_full_entry}
                posterSrc={entry.poster.path}
                hoverText={entry.hover_text}
                entryId={entry._id}
                externalLink={entry.external_link}
                isMobile={isMobile}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.column}>
        {entriesColumn2.map((entry) => {
          return (
            <div key={entry._id} className={styles.entry}>
              <GridElement
                isFullEntry={entry.is_full_entry}
                posterSrc={entry.poster.path}
                hoverText={entry.hover_text}
                entryId={entry._id}
                externalLink={entry.external_link}
                isMobile={isMobile}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.column}>
        {entriesColumn3.map((entry) => {
          return (
            <div key={entry._id} className={styles.entry}>
              <GridElement
                isFullEntry={entry.is_full_entry}
                posterSrc={entry.poster.path}
                hoverText={entry.hover_text}
                entryId={entry._id}
                externalLink={entry.external_link}
                isMobile={isMobile}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Desktop;
