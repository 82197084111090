// In case some consts change depending on environment, check out this article:
//   https://a-carreras-c.medium.com/development-and-production-variables-for-react-apps-c04af8b430a5

export const API_URL = 'https://content.anninamossoni.ch/api/';
export const RESOURCE_URL_PREFIX = 'https://content.anninamossoni.ch/';

// Remember to also change this under 'public/index.html'
export const SEO_DESCRIPTION = 'Annina Mossoni - Sängerin, Songwriterin';

// Remember to also change this under 'src/variables.scss'
export const BREAKPOINT_PHONE_MAX = 899;
